import { useEffect, useState } from 'react';
import { Stats, TrancheProgrammeModel, TypeTravauxModel } from '../data/models';
import ProjetGlobalStats from '../components/dashboard/ProjetGlobalStats';
import env from '../utils/env';
import { useFetch } from '../hooks/useFetch';
import AvancementFinancierStats from '../components/dashboard/AvancementFinancierStats';
import ProjetParRegionStats from '../components/dashboard/ProjetParRegionStats';
import ProjetParStatutStats from '../components/dashboard/ProjetParStatutStats';
import ProjetParPhaseStats from '../components/dashboard/ProjetParPhaseStats';
import Reporting from '../components/dashboard/Reporting';
import { useAuthContext } from '../contexts/AuthContextProvider';
import { useDownload } from '../hooks/useDownload';

const DashboardPage = () => {

  const [pieChartSeries, setPieChartSeries] = useState<number[]>([]);
  const [pieChartLabels, setPieChartLabels] = useState<string[]>([]);
  const [typeTravaux, setTypeTravaux] = useState<string>('CONSTRUCTION');
  const [currentTranche, setCurrentTranche] = useState<string>();
  const [error, setError] = useState<string>('');

  const { authState } = useAuthContext();
  const authorizationHeader = authState.token;

  const { data: projetParTravaux, error: loadProjetParTravauxError } = useFetch<Stats>(
    `${env.API_URL}/api/statistics/projets/repartition/travaux`
  );

  const { data: projetsDecomptes, error: loadProjetsDecomptesError } = useFetch<Stats>(
    `${env.API_URL}/api/statistics/projets/repartition/decompte`
  );

  const { data: projetParRegion, error: loadProjetParRegionError } = useFetch<Stats>(
    `${env.API_URL}/api/statistics/projets/repartition/region`
  );

  const { data: projetParStatut, error: loadProjetParStatutError } = useFetch<Stats>(
    `${env.API_URL}/api/statistics/projets/repartition/statut`
  );

  const { data: projetParPhase, error: loadProjetParPhaseError } = useFetch<Stats>(
    `${env.API_URL}/api/statistics/projets/repartition/phase/${typeTravaux}`
  );

  const { data: typesTravaux, error: loadTypesTravauxError } = useFetch<TypeTravauxModel[]>(
    `${env.API_URL}/api/refs/travaux`
  );

  const { data: tranches, error: loadTranchesError } = useFetch<TrancheProgrammeModel[]>(
    `${env.API_URL}/api/refs/projet/tranches`
  );

  useEffect(() => {
    let chartLabels: string[] = [];
    let chartSeries: number[] = [];
    if (projetParPhase?.repartition) {
      projetParPhase.repartition.forEach((partition) => {
        chartLabels.push(partition.sujet);
        chartSeries.push(parseInt(partition.valeur));
      });
    }
    setPieChartSeries(chartSeries);
    setPieChartLabels(chartLabels);
  }, [projetParPhase]);

  const handleTypeTravauxChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setTypeTravaux(value);
  }

  const handleTrancheChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setCurrentTranche(value);
  }

  const { download, file, error: downloadError, filename } = useDownload(
    `${env.API_URL}/api/documents/reporting/${currentTranche}`
  );

  useEffect(() => {
    if (downloadError) {
      setError(downloadError);
    }
  }, [downloadError]);

  const handleGenererRapportClick = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      download();
    } catch (e) {
      setError("Une erreur est survenue lors de l'envoi du formulaire");
    }
  }

  return (
    <>
      <div className="w-full lg:ps-64">
        <div className="p-6 space-y-6">
          <Reporting
            tranches={tranches}
            currentTranche={currentTranche}
            handleTrancheChange={handleTrancheChange}
            handleGenererRapportClick={handleGenererRapportClick} />
          {projetParTravaux && (
            <ProjetGlobalStats stats={projetParTravaux} />
          )}
          {projetsDecomptes && (
            <AvancementFinancierStats stats={projetsDecomptes} />
          )}
          {projetParRegion && (
            <ProjetParRegionStats stats={projetParRegion} />
          )}
          {projetParStatut && (
            <ProjetParStatutStats stats={projetParStatut} />
          )}
          {projetParPhase && (
            <ProjetParPhaseStats
              stats={projetParPhase}
              typesTravaux={typesTravaux}
              currentTypeTravaux={typeTravaux}
              handleTypeTravauxChange={handleTypeTravauxChange}
              chartSeries={pieChartSeries} chartLabels={pieChartLabels} />
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardPage;